import React from "react";
import { Link } from "gatsby";
import BlockContent from "@sanity/block-content-to-react";
import SEO from "../components/seo";

/* FAQS Page
============================================================================= */

const FAQSPage = ({ data }) => {
	// Config
	const { sanityFaq } = data;
	const { _rawFaqContent: faqs } = sanityFaq;

	// Render
	return (
		<>
			<SEO title="FAQS" />
			<div className="w-full lg:py-8 py-4 bg-darkblue">
				<div className="w-full container container-padding mx-auto flex flex-col items-center justify-center">
					<h2 className="text-white ibm-plex text-5xl font-sans font-black pb-4">
						FAQS
					</h2>
					<p className="text-white ibm-plex text-lg pb-4">
						Got a question?
					</p>
				</div>
			</div>
			<div className="w-full py-8">
				<div className="w-full container container-padding mx-auto flex flex-col items-center justify-center">
					<p className="text-darkblue ibm-plex text-lg pb-4 block-content lg:w-3/5 w-4/5">
						<BlockContent blocks={faqs} />
					</p>
				</div>
			</div>
		</>
	);
};

/* Page Query
============================================================================= */

export const pageQuery = graphql`
	query FAQPageQuery {
		sanityFaq {
			_rawFaqContent(resolveReferences: { maxDepth: 10 })
		}
	}
`;

/* Export
============================================================================= */

export default FAQSPage;
